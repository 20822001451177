import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import hero from '../assets/images/hero.jpg'

const ctaBgStyle = {
  backgroundColor: 'black',
  backgroundSize: 'cover',
  backgroundImage: `url(${hero})`,
}

const ctaHeader = {
  color: 'white',
  fontWeight: '600',
  textShadow: 'black 0px 2px 2px',
}

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Bangor Transit Study" />

        <Header />

        <div id="main">
          <section id="survey" className="main special" style={ctaBgStyle}>
            <header className="major">
              <h2 style={ctaHeader}>The survey has closed.</h2>
              <p style={ctaHeader}>Thanks to all those who participated!</p>
              <p>&nbsp;</p>
            </header>
          </section>
          <section id="intro" className="main">
            <header className="major">
              <h2>What is this project?</h2>
            </header>
            <p>
              The Community Connector is a fixed route bus system serving the
              Greater Bangor Urbanized Area, operating within Bangor, Brewer,
              Hampden, Old Town, Orono, the University of Maine (Orono), and
              Veazie. This project looks at how the existing Community Connector
              system can be changed to better meet community needs. In the
              context of this project, the bus network, schedules, fares, fleet,
              technologies, policies, and processes will be reviewed.
            </p>

            <header className="major">
              <h2>Why are we doing this project now?</h2>
            </header>
            <p>
              The current bus system has been generally unchanged since the
              Community Connector’s inception and residents of the Greater
              Bangor Urbanized Area want better transit service. This project
              will result in short-range and long-range strategic plans aimed at
              improving operational efficiency, ultimately leading to user
              benefits such as enhanced bus reliability, shorter travel times,
              and more.{' '}
            </p>

            <header id="feedback" className="major">
              <h2>Feedback...</h2>
            </header>
            <p>
              If you are interested in providing feedback, please let us know:{' '}
              <a href="mailto:feedback@bangortransit.study">
                feedback@bangortransit.study
              </a>
            </p>

            <header id="feedback" className="major">
              <h2>Update</h2>
            </header>
            <h3>2019-04-11</h3>
            <p>
              In February, we met with stakeholders, community members, and held
              several public meetings across Bangor. We also conducted an online
              survey of bus riders and non-riders. We heard that buses are not
              frequent enough and that buses do not operate late enough to be
              convenient. Customers are requesting that buses operate later into
              the evening.
            </p>

            <p>
              Based on our analyses of the transit market, much of the service
              area of the Community Connector is low-density and with separated
              land uses, meaning that typically, people need to travel long
              distances to shopping or other opportunities that favor car trips.
              While transit can serve some of these trips, some of the
              productivity and effectiveness of transit is eroded by low-density
              sprawl. Nevertheless, some sociodemographic characteristics are
              favorable to transit use, including the concentration of older
              adults as well as students in the service area.
            </p>

            <p>
              Of the routes operated, Capehart, Old Town, and Hammond carry
              nearly 45% of all weekday ridership. The least used routes are
              Hampden and Mall Hopper. In terms of productivity, that is, riders
              carried per hour of service, Hammond and Stillwater perform best,
              while Mall Hopper and Hampden perform the worst.
            </p>

            <p>
              We are currently finalizing an existing conditions report that
              will help us identify gaps, challenges, and opportunities to
              improve service based on the public’s input, our analyses, and
              best practices.
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
