import React from 'react'

import logo from '../assets/images/logo.png'

const Header = props => (
  <header id="header" className="alt">
    {/* <div className="banner"> */}
    <h1>Bangor Area Transit Study</h1>
    {/* </div> */}
    <img className="logo" src={logo} />
  </header>
)

export default Header
